@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/images';

$headerHeight: 64px;

.root {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  padding: 0 30px;
  min-height: $headerHeight;
  display: flex;
  background-color: $white;
  z-index: 5;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.headerContainer {
  width: 100%;
  max-width: 1470px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.userImage {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  object-fit: cover;
}

.logoContainer {
  height: 50px;
}

.logoImg {
  height: 100%;
  width: auto;
}

.content {
  position: relative;
  flex-grow: 1;
  padding-bottom: 18px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.contentCoverBG {
  position: absolute;
  left: 0;
  top: 0;
  height: 260px;
  width: 100%;
  background: linear-gradient(115deg, #2B307D 0%, #31ABB2 100%);

  &:after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url($bg-patern);
    opacity: 0.1;
  }
}

.contentContainer {
  width: 100%;
  max-width: 1470px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
}

.mainBlock {
  display: grid;
  width: 100%;
  grid-template: 'content';
  grid-template-columns: auto;
  position: relative;
  margin: 30px 0 10px;
  grid-gap: 42px;
}

.mainBlockNoNav {
  display: grid;
  width: 100%;
  grid-template: 'content';
  grid-template-columns: 1fr;
  position: relative;
  margin: 60px 0 10px;
  grid-gap: 42px;
}

.contentHolder {
  //flex-grow: 1;
  //max-width: 100%;
  //display: flex;
  //justify-content: center;
}

.controlsBlock {
  display: flex;
  gap: 20px;
  margin-left: auto;
  align-items: center;
}

.logoutButton {
  margin-top: 8px;
}

.notificationsHolder {
  position: absolute;
  top: $headerHeight;
  z-index: 2;
  width: 100%;
}
