@import '../../../../styles/colors';

.root {
  position: relative;
  border-radius: 10px;
  width: 88px;
  height: 88px;
  overflow: hidden;
}

.userAvatar {
  width: 100%;
  height: 100%;
  border-radius: 64px;
  object-fit: cover;
}

.userNoAvatar {
  width: 100%;
  height: 100%;
  border-radius: 64px;
  background-color: #0066FF;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -1px 1px 0px rgba(0, 0, 0, 0.15);
  font-size: 36px;
  font-weight: bold;
}

.overlay {
  cursor: pointer;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(196, 203, 214, 0.6);
  transition: all 0.1s;
  border-radius: 50%;
}

.hidden {
  display: none;
}

.root:hover .overlay {
  opacity: 1;
}

.editIcon {
  width: 32px;
  height: 32px;
  fill: $white;
}
