@import '../../styles/colors';
@import '../../styles/fonts';

.companyHeaderContainer {
  padding: 30px 44px 20px;
  border-bottom: 1px solid $secondary-border;
}

.formFieldsContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.submitButtonContainer {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

}

.form {
  width: 100%;
}

.companyPageContent {
  width: 50%;
}

.title {
  @include heading0;
  margin-bottom: 36px;
}

.mainTitle {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 50px 0 20px;

  &:first-child {
    margin: 20px 0;
  }
}

.titleIcon {
  width: 20px;
  height: auto;
}

.titleText {
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #000;
}

.companySidebarNav {
  background: $white;
  border: 1px solid $secondary-border;
  border-radius: 9px;
  padding: 44px;
}

.sidebarNavList {
  display: flex;
  flex-direction: column;
  gap: 30px;
}


.sidebarNavListItem {
  cursor: pointer;
  font-size: 16px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebarNavListItemActive {
  color: $blue-normal;
  font-weight: 700;
}