@import '../../../../../styles/colors';
@import '../../../../../styles/fonts';

.tableRow {
  @include text3;
  height: 80px;
  transition: all 0.2s;
  background-color: $white;

  td {
    border-bottom: solid 1px $grey-lighter;
  }

  &:hover {
    background-color: #f2f6fa;
  }
}

.tableCell {
  text-align: center;
  vertical-align: middle;
}

.emptyTableRow {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include text2;
}