@import 'styles/colors';
@import 'styles/fonts';

.root {
  user-select: none;
  position: relative;
}

.menuTitle {
  @include text3;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
}

.arrowIcon {
  width: 14px;
  height: auto;
  transition: all 0.2s;

  &.inverted {
    transform: rotate(180deg);
  }
}

.optionsHolder {
  position: absolute;
  overflow: hidden;
  display: none;
  top: 100%;
  transform: translateY(20px);
  right: 0;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(196, 203, 214, 0.6);
  transition: all 0.2s;
  width: 280px;
  padding: 15px 0;
  background-color: $white;

  &.open {
    display: block;
    animation: slideDown 0.4s;
  }
}

.userImage {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  object-fit: cover;
}

.option {
  @include text3;
  padding: 15px 30px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: $light-blue;
  }
}

.dropdownHeader {
  background-color: $cyan;
  color: $white;
  padding: 15px 30px;
  font-weight: 600;
  margin: -14px 1px 1px 1px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

@keyframes slideDown {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  60% {
    transform: translateY(25px);
    opacity: 0.8;
  }
  100% {
    transform: translateY(20px);
    opacity: 1;
  }
}
