@import '../../../styles/colors';
@import '../../../styles/fonts';

.root {
  width: 100%;
}

.table {
  width: 100%;
  overflow: hidden;
}

.tableHolder {
  position: relative;
}

.tableHeaderHolder {
  // overflow: scroll;
  // position: sticky;
  // top: 0;
  // z-index: 1;
  border-top: 1px solid $grey-lighter;
  border-bottom: 1px solid $grey-lighter;
  background-color: $white;
  height: 50px;
  display: flex;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.noEntriesBlock {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background: $white;
  min-height: 500px;
}

.stickyLabel {
  @include heading2;
  position: sticky;
  left: 50%;
  color: $grey;
}

.hidden {
  visibility: hidden;
  height: 0;
  position: absolute;
}
