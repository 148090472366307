.root {
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.submitButton {
  margin-top: 24px;
  align-self: flex-start;
}

.pairedFieldsContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;

  & > * {
    flex-grow: 1;
  }
}

.titleFieldContainer {
  max-width: 100px;
  min-width: 100px;
  flex-grow: 1;
}

.mainTitle {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 50px 0 20px;
}

.titleIcon {
  width: 20px;
  height: auto;
}

.titleText {
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #000;
}
