@import '../../styles/fonts';
@import '../../styles/colors';

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.userBlock {
  display: flex;
  align-items: center;
  padding-left: 20px;
  gap: 16px;
}

.userTextContainer {
  margin-top: -10px;
}

.profileImage {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  object-fit: cover;
  background-color: $light-grey-0;
}

.fullName {
  @include heading3;
  color: $black;
  text-align: left;
}

.title {
  font-size: 12px;
  text-align: left;
}

.email {
  font-size: 12px;
  text-align: left;
  color: $black;
}

.status {
  font-size: 12px;
  color: $black;
  font-weight: bold;

  &.active {
    color: $green-normal;
  }

  &.pending {
    color: $orange-normal;
  }
}

.modules {
  font-size: 12px;
  color: $black;
}

.root :global(table) {
  table-layout: fixed;

  thead {
    th:nth-child(1) > div {
      justify-content: flex-start;
    }

    th:nth-child(1) > div {
      padding-left: 62px;
    }
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchButton {
  input {
    background-color: $secondary-bg;
    color: $black;
    border: 1px solid $secondary-border;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-size: 14px;
    width: 280px;
    height: 40px;
    background-image: url('/static/icons/search.svg');
    background-size: 14px 14px;
    background-repeat: no-repeat;
    background-position: 10px 12px;
    padding: 14px 10px 14px 35px;
  }
}

.inviteButton {
  background-color: $secondary-bg;
  color: $black;
  border: 1px solid $secondary-border;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  font-size: 14px;
  width: 150px;
  height: 40px;
  padding: 4px 10px;

  &:hover {
    background-color: $blue-normal-hover;
    color: $white;
    font-weight: bold;

    svg {
      -webkit-filter: invert(100%);
      filter: invert(100%);
    }
  }

  &:active {
    background-color: $blue-normal-active;
    color: $white;
    font-weight: bold;
  }

  &:disabled {
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.25);
    font-weight: bold;
  }
}