@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.root {
  @include text3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid $light-grey;
  color: $dark-grey;
}

.fileName {
  color: $text-dark-grey;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.fileDate {
 color: $text-light-grey;
 font-size: 14px;
 line-height: 18px;
}

.downloadBlock {
  @include text3;
  display: flex;
  gap: 12px;
  align-items: center;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  border: 0;
}

.downloadIcon {
  width: 28px;
  height: auto;

  &:hover,
  &:active {
    path {
      fill: $blue-normal-hover;
    }
  }
}