@import 'styles/colors';
@import 'styles/fonts';

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 472px;

  &:not(:last-child) {
    border-right: 1px solid $light-grey;
  }
}

.title {
  padding: 15px 23px;
  font-size: 14px;
  font-weight: 600;
  color: $font-black;
}

.listItem {
  padding: 15px 23px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  color: $font-black;

  &:hover:not(.selected) {
    background-color: $light-grey;
  }

  &.selected {
    color: $blue-normal;
    background-color: $light-blue;
  }

  transition: all 0.2s;
}

.searchIcon {
  fill: $font-black;
  cursor: pointer;
  margin-left: 4px;
  width: 12px;
  height: 12px;
}

.crossIcon {
  fill: $font-black;
  cursor: pointer;
  margin-right: 4px;
  width: 20px;
  height: 20px;
}

.headerBlock {
  position: relative;
  padding: 10px;
  border-bottom: 1px solid $light-grey;;
}

.listHolder{
  padding: 10px;
}

.searchHolder {
  position: absolute;
  align-items: center;
  display: flex;
  top: 0;
  width: 100%;
}
