@import 'styles/colors';
@import 'styles/fonts';

.root {
  display: inline-block;
  width: 100%;
}
.select {
  @include text3;
  line-height: 1.25;

  &:focus-visible {
    border-color: $grey;
  }

  [class$='-indicatorSeparator'] {
    display: none;
  }

  [class$='-control'] {
    border-radius: 4px;
    height: 40px;
    border-color: $secondary-border;
    width: 100%;
  }

  [class$='-menu'] {
    overflow: hidden;
  }

  [class$='-multiValue'] {
    background-color: $blue-normal;
    border-radius: 6px;

    [class^='css'] {
      @include text3;
      color: $white;
    }
  }
}

.hasError [class$='-control'] {
  border-color: $red;
}

.errorMessage {
  color: $red;
  @include text3;
  position: absolute;
}

.defaultValue {
  display: none;
}

[class$=-menu] > div > [class$=-option] {
  color: $black;
}