@import '../../../../styles/fonts';
@import '../../../../styles/colors';

.row {
  display: flex;
  gap: 14px;
  margin-bottom: 30px;
  color: $text-light-grey;
  font-size: 14px;
}

.title {
  @include heading2;
  margin: 18px 0 10px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $light-grey;
}
