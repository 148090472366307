@import '../../styles/fonts';
@import '../../styles/colors';

.root {
 width: 100%;
}

.navigationContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navLink {
  color: $text-light-grey;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;

  &.active {
    color: $black;
    font-weight: bold;
  }
}

.pageTitle {
  font-size: 32px;
  font-weight: 800;
  margin-top: 20px;
  margin-bottom: 36px;
}