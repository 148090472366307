@import '../../../../styles/fonts';
@import '../../../../styles/colors';
@import '../../../../styles/input';

.root {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
}

.userInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
}

.usernameContainer {
  display: flex;
  gap: 6px;
  align-items: center;

  &.editMode {
    display: grid;
    grid-template: 'first_name last_name icon';
  }
}

.editIcon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  fill: $font-black;
}

.closeIcon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  fill: $font-black;
}

.username {
  @include heading1;
}

.userTitle {
  @include text3;
}

.actions {
  display: flex;
  flex-direction: column;

  .changeAvatar {
    width: 150px;
    height: 32px;
    border-radius: 4px;
    background-color: $white;
    border: 1px solid $grey-light;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 13px;
    color: #283C46;
    display: inline-block;

    &:hover {
      background-color: $blue-normal-hover;
      color: $white;
      font-weight: bold;
    }

    &:active {
      background-color: $blue-normal-active;
      color: $white;
      font-weight: bold;
    }

    &:disabled {
      background-color: rgba(0, 0, 0, 0.05);
      color: rgba(0, 0, 0, 0.25);
      font-weight: bold;
    }
  }

  .changeAvatarLink {
    color: $blue-normal;
    font-size: 13px;
    text-decoration: underline;
    width: 100%;
    height: 15px;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }
}

.hidden {
  display: none;
}

.fieldAction {
  display: flex;
  align-items: center;
  gap: 10px;
}

.actionButton {
  background-color: transparent;
  border: 0;
  text-decoration: underline;
  color: $text-dark-grey;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  cursor: pointer;
}
