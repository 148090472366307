@import '../../../../styles/fonts';

.root {
  padding: 10px 0;
  width: 100%;
}

.infoHeading {
  @include heading2;
  margin: 0 0 18px;

  &:not(:first-of-type) {
    margin: 48px 0 18px;
  }
}

.fieldsContainer {
  display: flex;
  flex-direction: column;
  margin-left: 4px;
}

.pairedFieldsContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;

  .row {
    display: flex;
    gap: 10px;
  }
}