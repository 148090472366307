@import '../../styles/colors';

.root {
  width: 100%;
}

.form {
  width: 50%;
}

.formActions {
  width: 100%;
  margin-top: 20px;
}

.submitButton {
  width: 100%;
  height: 47px;
  border-radius: 4px;
  background-color: $blue-normal;
  color: $white;
  font-size: 16px;
}