@import '../../styles/fonts';

.root {
  width: 100%;
}

.content {
  padding: 20px 12px 64px 20px;
  width: 50%;
}

.fileBlocksContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  color: $text-dark-grey;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  margin-bottom: 10px;
}