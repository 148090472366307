@import 'styles/colors';
@import 'styles/fonts';

.root {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  min-height: 40px;
  position: relative;
  
  .pagination {
    width: auto;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: $white;
    color: $black;
    // border: 1px solid $secondary-border;
    // border-radius: 4px;
  }

  .paginationButton + .paginationButton:not(.numberButton) {
    margin-left: 5px;
  }
}

.pageNumberButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin: 0 12px;
}

.paginationButton {
  @include text23;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  min-width: 32px;
  width: auto;
  padding: 0 10px;
  cursor: pointer;
  border: 0;
  background: none;
  border-radius: 4px;

  &.active {
    background-color: $blue-normal;
    border-color: $blue-normal;
    color: $white;
    font-weight: 600;
  }

  &:disabled {
    color: $grey-2;
    cursor: default;
    border-color: $light-grey;
  }

  &:hover:not(.active):not(:disabled) {
    background-color: $light-grey;
  }
}
