@import './colors';

@mixin resetFont {
  letter-spacing: normal;
  text-transform: none;
  text-decoration: none;
  color: $font-black;
}

@mixin heading0 {
  @include resetFont;
  font-size: 34px;
  font-weight: 700;
}

@mixin heading1 {
  @include resetFont;
  font-size: 22px;
  font-weight: 700;
}

@mixin heading2 {
  @include resetFont;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
}

@mixin heading3 {
  @include resetFont;
  color: $font-grey;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
}

@mixin heading4 {
  @include resetFont;
  color: $font-grey;
  font-size: 10px;
  line-height: 24px;
  font-weight: 700;
}

@mixin text1 {
  @include resetFont;
  font-size: 22px;
  font-weight: normal;
}

@mixin text2 {
  @include resetFont;
  font-size: 18px;
  font-weight: normal;
}

@mixin text23 {
  @include resetFont;
  font-size: 16px;
  font-weight: normal;
}
@mixin text3 {
  @include resetFont;
  font-size: 14px;
  font-weight: normal;
}

@mixin text4 {
  @include resetFont;
  font-size: 10px;
  font-weight: normal;
}
