@import 'styles/colors';

.root {
  display: flex;
  align-items: center;
}

.checkMarker {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  z-index: 1;
}

.checkmark {
  height: 17px;
  width: 17px;
  position: relative;
  border-radius: 3px;
  margin: 1px 7px 0 0;
  border: 1px solid $grey-2;
  background-color: $white;

  &:after {
    left: 5px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid $white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.checkboxContainer {
  display: flex;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.checkboxContainer:hover .checkMarker:not(:checked) ~ .checkmark {
  background-color: $light-grey-2;
}

.checkMarker:checked ~ .checkmark {
  background-color: $cyan;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkMarker:checked ~ .checkmark:after {
  display: block;
}

.checkboxLabel {
  margin-left: 8px;
  cursor: pointer;
}
