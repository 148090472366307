@import '../../../../styles/colors';
@import '../../../../styles/fonts';

.root {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: transparentize($grey, 0.2);
  cursor: default;
}

.wrapper {
  position: relative;
  width: 500px;
  padding: 0 0 20px 0;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 4px 4px;
  border: solid 1px $light-grey;
  background-color: $white;
  gap: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.10);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 0px 0px #ECECEE;
  height: 40px;
  padding: 0 20px;

  .title {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
  }

  button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }
  .icon {
    width: 16px;
    height: 16px;
  }
}

.inviteFormContainer {
  padding: 0 20px;

  .nameBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;
  }

  .inputBox {
    width: 100%;

    &.required {
      position: relative;

      div[data-lastpass-icon-root] {
        &::after {
          content: none;
        }
      }

      ::after {
        content: '*';
        position: absolute;
        color: red;
        right: 5px;
        top: 5px;
        font-size: 10px;
      }
    }
  }
}

.inviteFormControlsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;

  button {
    width: 95px;
  }
}