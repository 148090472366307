@import 'styles/fonts';
@import 'styles/colors';

.root {
  position: relative;
}

.heading {
  @include heading0;
  margin-bottom: 12px;
  text-align: center;
}

.text {
  @include text2;
  color: $dark-grey;
  text-align: center;
}

.subtitle {
  @include text2;
  color: $font-grey;
  line-height: 32px;
  text-align: center;
}

.appsIcon {
  height: auto;
  width: 20px;
  transform: translateY(4px);
  fill: $grey-2;

  .text > & {
    margin-right: 12px;
  }

  .subtitle > & {
    margin: 0 2px;
  }
}

.modulesList {
  margin: 40px 0;
  padding: 40px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 35px;
  border-top: solid 1px $light-grey;
}

.moduleCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  gap: 12px;
  width: 256px;
  height: 100px;
  border-radius: 15px;
  padding: 10px;
  border: 1px solid $light-grey-2;
  box-shadow: 0 20px 40px -30px $grey-2;
  transition: transform 0.5s, box-shadow 0.3s;
  background-color: $white;
  user-select: none;
  cursor: pointer;

  svg {
    height: 32px;
    width: auto;
  }

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 25px 50px -30px $grey-2;
  }
}

.moduleName {
  @include heading3;
}
