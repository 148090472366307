$black: #000000;
$font-black: #110F24;
$font-grey: #7d8592;
$white: #ffffff;
$dark-grey: #494852;
$grey: #999999;
$grey-2: #b2b9c4;
$light-grey-0: #e4e4e4;
$light-grey: #efefef;
$light-grey-2: #fafbfc;
$light-grey-3: #f1f1f1;

$green: #6cba70;
$light-green: #e0f9e9;
$cyan: #27bac2;
$light-cyan: #27bac222;
$blue: #377dff;
$light-blue: #eaf3ff;
$light-blue-2: #f6faff;
$red: #dd3939;
$light-red: #fdc3c4;
$orange: #ffa500;
$light-orange: #fedab7;

$default-text: #092737;


//New colors
// Colors variables

$default-bg: #FBFBFB;
$secondary-bg: #F6F6F9;
$secondary-border: #ECECEE;
$input-disabled-color:#B0B0B0;

// Blue
$blue-light: #e8f0ff;
$blue-light-hover: #dce9fe;
$blue-light-active: #b7d1fe;

$blue-normal: #176bfb;
$blue-normal-hover: #1560e2;
$blue-normal-active: #1256c9;

$blue-dark: #1150bc;
$blue-dark-hover: #0e4097;
$blue-dark-active: #0a3071;

$blue-darker: #082558;

//Xelacore Brand Colour
$brand-normal: #00aaad;
$brand-normal-hover: #00999c;
$brand-normal-active: #00888a;

// Dark Grey
$grey-lighter: #efefef;
$grey-light: #eaeaea;
$grey-light-hover: #dfdfe0;
$grey-light-active: #bdbdbf;

$grey-normal: #2a2b30;
$grey-normal-hover: #26272b;
$grey-normal-active: #222226;

$grey-dark: #202024;
$grey-dark-hover: #191a1d;
$grey-dark-active: #131316;

$grey-darker: #0f0f11;


$warning-bg: #FF7127;
$success-bg: #36B62B;

//Error Red
$red-normal: #ff0101;
$red-normal-hover: #e60101;
$red-normal-active: #cc0101;

// Positive Green
$green-light: #e7f3e8;
$green-light-hover: #daeddc;
$green-light-active: #b3dab7;

$green-normal: #0b8917;
$green-normal-hover: #0a7b15;
$green-normal-active: #096e12;

$green-dark: #086711;
$green-dark-hover: #07520e;
$green-dark-active: #053e0a;

$green-darker: #043008;


$brand-colour: #00AAAD;

//new colors
$text-light-grey: #717171;
$text-dark-grey: #222222;
$orange-normal: #ff7127;
