@import 'styles/colors';
@import 'styles/fonts';


.root {
  font-size: 12px;
  color: $black;
  font-weight: 600;
  letter-spacing: 1px;
  height: 40px;


  & > div {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    align-items: center;
    justify-content: center;
    position: relative;
  }
}

.iconHolder {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sortHolder {
  margin-right: 5px;
}

.filterHolder {
  margin-left: 5px;
}

.unsortedBlock {
  display: flex;
  flex-direction: column;
  margin-top: -10px;
}

.unsortedArrow {
  margin-bottom: -10px;
}

.filterIcon {
  fill: $black;
  width: 16px;
  margin-bottom: -6px;
  transform: translateY(-2px);
}

.sortArrow {
  fill: $black;
  width: 16px;
  transition: all 0.2s;

  &.up {
    transform: rotate(180deg);
  }
}

.overlay {
  position: fixed;
  cursor: default;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.4;
  z-index: 1;
}
