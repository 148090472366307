@import '../../styles/colors';

.root {
  width: 100%;
}

.form {
  width: 50%;
}

.formActions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0 10px;
}
