@import 'styles/fonts';

.root {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.subtitle {
  @include text1;
}
