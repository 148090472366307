@import '../../styles/colors';
@import '../../styles/fonts';

.root {
  width: 100%;
}

.cardsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.title {
  @include heading0;
  margin-bottom: 36px;
}