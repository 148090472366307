@import '../../../styles/fonts';
@import '../../../styles/colors';

.root {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  height: 80px;
  margin-bottom: 10px;

  &.border {
    border-bottom: 1px solid $light-grey;
  }

  &.field {
    border-bottom: 0;

    .fieldRow {
      width: 100%;
    }
  }
}

.fieldLabel {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  color: $text-dark-grey;
}

.filedValue {
  margin: 5px 0;
  font-size: 14px;
  line-height: 18px;
  color: $text-light-grey;

  &.notProvided {
    font-size: 12px;
    font-style: italic;
  }
}

.fieldAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actionButton {
  background-color: transparent;
  border: 0;
  text-decoration: underline;
  color: $text-dark-grey;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
}

.fieldRow {
  width: 100%;
}