@import '../../../styles/colors';
@import '../../../styles/fonts';

.card {
  width: 328px;
  height: 156px;
  margin: 10px 10px 10px 0;
  padding: 16px;
  border: 1px solid $secondary-border;
  border-radius: 7px;
  background-color: $white;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.12);
  }

  img {
    width: 28px;
    height: 28px;
    margin-bottom: 10px;
  }

  .cardTitle {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: $black;
    margin-bottom: 10px;
  }

  .cardDescription {
    font-size: 14px;
    line-height: 18px;
    color: $text-light-grey;
    width: 80%;
  }

  &.disabled {
    cursor: initial;
    background-color: #F86A6A;

    .cardTitle,
    .cardDescription {
      color: #FF9999;
    }
  }
}
