@import 'styles/colors';

$action-button-colors: (
  'action-bg-color-default': #176BFB,
  'action-bg-color-hover': #1560E2,
  'action-bg-color-disabled': rgba(0, 0, 0, 0.1),
  'action-font-color-default': $white,
  'action-font-color-hover': $white,
  'action-font-color-disabled': rgba(0, 0, 0, 0.2),
);

$primary-button-colors: (
  'primary-bg-color-default': $white,
  'primary-bg-color-hover': $blue-normal,
  'primary-bg-color-disabled':  rgba(0, 0, 0, 0.1),
  'primary-font-color-default': $blue-normal,
  'primary-font-color-hover': $white,
  'primary-font-color-disabled': rgba(0, 0, 0, 0.2),
);

$danger-button-colors: (
  'danger-bg-color-default': $red-normal,
  'danger-bg-color-hover': $red-normal-hover,
  'danger-bg-color-disabled': #d1d1d1,
  'danger-font-color-default': $white,
  'danger-font-color-hover': $white,
  'danger-font-color-disabled': $white,
);

$danger2-button-colors: (
  'danger2-bg-color-default': $secondary-bg,
  'danger2-bg-color-hover': $red-normal-hover,
  'danger2-bg-color-disabled': #d1d1d1,
  'danger2-font-color-default': $black,
  'danger2-font-color-hover': $white,
  'danger2-font-color-disabled': $white,
);

$text-button-colors: (
  'text-bg-color-default': rgba(255, 255, 255, 0),
  'text-bg-color-hover': #e9f7f8,
  'text-bg-color-disabled': rgba(255, 255, 255, 0),
  'text-font-color-default': $blue-normal,
  'text-font-color-hover': $blue-normal-hover,
  'text-font-color-disabled': #d1d1d1,
);

$large-button-sizes: (
  'medium-button-font-size': 14px,
  'large-button-height': 48px,
  'large-button-padding': 0 36px,
  'large-button-min-width': 100px,
  'large-button-icon-margin': 12px,
);
$medium-button-sizes: (
  'medium-button-font-size': 14px,
  'medium-button-height': 32px,
  'medium-button-padding': 0 12px,
  'medium-button-min-width': 80px,
  'medium-button-icon-margin': 10px,
);
$small-button-sizes: (
  'small-button-font-size': 12px,
  'small-button-height': 20px,
  'small-button-padding': 0 10px,
  'small-button-min-width': 50px,
  'small-button-icon-margin': 6px,
);

$button-colors-map: map-merge(
  map-merge(map-merge(map-merge($action-button-colors, $primary-button-colors), $text-button-colors),
  $danger-button-colors), $danger2-button-colors
);

$button-sizes-map: map-merge(map-merge($large-button-sizes, $medium-button-sizes), $small-button-sizes);

@mixin button-stylings($type) {
  background: map-get($button-colors-map, '#{$type}-bg-color-default');
  color: map-get($button-colors-map, '#{$type}-font-color-default');

  svg {
    fill: map-get($button-colors-map, '#{$type}-font-color-default');
  }

  .iconHolder {
    color: map-get($button-colors-map, '#{$type}-font-color-default');
  }

  @if ($type == 'primary') {
    border: 1px solid $primary-border-color;
    box-shadow: 0 3px 6px 0 $primary-shadow-color;
  } @else if ($type == 'danger2'){
    border: 1px solid $secondary-border;
    box-shadow: none;
  } @else {
    border: 1px solid transparent;
    box-shadow: none;
  }

  @if ($type == 'text') {
    font-weight: normal;
  } @else {
    font-weight: 700;
  }

  &:hover {
    background: map-get($button-colors-map, '#{$type}-bg-color-hover');
    color: map-get($button-colors-map, '#{$type}-font-color-hover');

    svg {
      fill: map-get($button-colors-map, '#{$type}-font-color-hover');
    }

    .iconHolder {
      color: map-get($button-colors-map, '#{$type}-font-color-hover');
    }

    box-shadow: none;

    &:not(:disabled) {
      border: 1px solid transparent;
    }
  }

  &:disabled {
    background: map-get($button-colors-map, '#{$type}-bg-color-disabled');
    color: map-get($button-colors-map, '#{$type}-font-color-disabled');

    svg {
      fill: map-get($button-colors-map, '#{$type}-font-color-disabled');
    }

    box-shadow: none;
    cursor: default;
  }
}

@mixin button-size($size) {
  height: map-get($button-sizes-map, '#{$size}-button-height');
  padding: map-get($button-sizes-map, '#{$size}-button-padding');
  min-width: map-get($button-sizes-map, '#{$size}-button-min-width');
  font-size: map-get($button-sizes-map, '#{$size}-button-font-size');

  .iconHolder {
    &.left {
      margin-right: map-get($button-sizes-map, '#{$size}-button-icon-margin');
    }

    &.right {
      margin-left: map-get($button-sizes-map, '#{$size}-button-icon-margin');
    }
  }
}

$sizes: ('large', 'medium', 'small');
$types: ('primary', 'text', 'action', 'danger', 'danger2');

$primary-border-color: #d9d9d9;
$primary-shadow-color: #f3f3f3;

.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  text-decoration: none;

  border-radius: 4px;
  cursor: pointer;
  transition: color 0.1s, background 0.1s;

  @include button-stylings('primary');
  @include button-size('medium');

  @each $type in $types {
    &.#{$type} {
      @include button-stylings($type);
    }
  }

  @each $size in $sizes {
    &.#{$size} {
      @include button-size($size);
    }
  }

  svg {
    transition: fill 0.1s;
  }

  .iconHolder {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
