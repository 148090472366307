@import 'styles/colors';

.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
}

.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: $cyan;
  background: linear-gradient(to right, $cyan 10%, rgba(78, 124, 198, 0) 42%);
  position: relative;
  animation: load 1.4s infinite linear;
  transform: translateZ(0);
}

.loader:before {
  width: 50%;
  height: 50%;
  background: $cyan;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.loader:after {
  background: $white;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
