@import 'styles/fonts';

.root {
  width: 100%;
  background: $white;
  border: 1px solid $secondary-border;
  border-radius: 9px;
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}

.title {
  @include heading2;
  align-self: flex-start;
}

.listViewContainer {
  display: flex;
  width: 100%;
}
