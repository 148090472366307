
.input {
  @include text3;
  resize: none;
  border: 1px solid;
  background: $secondary-bg;
  border-color: $secondary-border;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding: 0 12px;
  transition: all 0.1s ease;
  outline: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &:focus-visible {
    border-color: $blue-normal;
    // box-shadow: inset 0 0 0 1px $blue-normal;
  }

  &.hasIcon {
    padding: 0 36px 0 12px;
  }

  &:disabled{
    background: rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: $input-disabled-color;
  }
}

.hasError {
  border-color: $red;
}

.errorMessage {
  @include text4;
  color: $red;
  position: absolute;
}

.iconHolder {
  position: absolute;
  right: 12px;
  top: 11px;
}
